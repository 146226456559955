:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.root {
  padding: 32px;
  background-image: url("./background.svg");
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: center; */
  background-color: var(--Foundation-100);
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.divHeader {
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divFormContainer {
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.imageLogo {
  width: auto;
  height: 40px;
  flex-shrink: 0;
}

.title {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.desctitle {
  color: var(--Grey-60);
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.labelemail {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 14px;
  margin: 5px 0 5px 0;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}
.labelemailInvalid {
  color: var(--Red-50);
  font-family: Inter;
  font-size: 14px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.field {
  display: flex;
  font-family: Inter;
  padding: 12px;
  width: 470px;
  height: 25px;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.textField {
  color: var(--Foundation-200, #3c3c3c);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  padding: 0;
}

.disabledBtn {
  border-radius: 8px;
  background: var(--Grey-20);
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-transform: capitalize;
  color: var(--Foundation-100);
  &:hover {
    background: var(--Grey-20);
  }
}

.activeBtn {
  border-radius: 8px;
  background: var(--Primary-100);
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-transform: capitalize;
  color: var(--Foundation-100);
  &:hover {
    background: var(--Primary-100);
  }
}

.textAlert12 {
  color: var(--Red-50);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.divFooter {
  position: fixed;
  bottom: 0;
  padding: 32px;
}

.textCopyright {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Grey-60);
}

.textDidntReceiveEmail {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textClickHereToResend {
  color: var(--Primary-100);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.084px;
  cursor: pointer;
}
