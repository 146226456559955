:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.root {
  padding: 32px;
  background-image: url("./background.svg");
  /* width: 100%; */
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: center; */
  background-color: var(--Foundation-100);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.divHeader {
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageLogo {
  width: auto;
  height: 30px;
  flex-shrink: 0;
}

.divContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60vh;
}

.divBody {
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
}

.divBodyRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
}

.sizeIcon {
  width: 400px;
  height: 400px;
}

.textTitle {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.672px;
  align-self: stretch;
}

.textSubtitle {
  color: var(--Grey-60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.252px;
  align-self: stretch;
}

.divFooter {
  position: fixed;
  bottom: 0;
  padding: 32px;
}
